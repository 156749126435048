@media screen and (max-width: 650px) {
  /* styles for browsers smaller than 650; */
  .result-container {
    transform: scale(0.8);
    margin: 0px !important;
  }
  .curtain {
    opacity: 0 !important;
  }
}

@@media screen and (min-width: 600px) {
  .result-container:hover {
    transform: scale(1.02);
    opacity: 1;
  }
}

/* style for mobile experience */
@media screen and (max-width: 420px) {
  .result-container {
    width: 80vw;
  }
  .search-results-container  {
    height: 75vh !important;
    overflow: scroll;
  }
  .curtain {
    position: relative !important;
    float: right;
    opacity: 1 !important;
  }
  .curtain-text {
    opacity: 1 !important;
    padding: 0px !important;
    padding-top: 10px !important; /*remove all padding first then add only top padding*/
    text-decoration: underline;
  }
  .search-result-btn {
    visibility: visible !important;
  }
  .curtain-desc {
    visibility: visible !important;
  }
}
@keyframes fade-in {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.9;
  }
}


.curtain-text {
  opacity: 0;
  font-size: 16px;
  padding: 30px;
  padding-top: 20%;
  padding-bottom: 50%; /* to make sure hover works properly*/
  padding-right: 5px;
  font-weight: 800;
  cursor: default;
}
.curtain-desc {
  padding-top: 10px;
  font-size: 14px;
  opacity: 0.9;
  visibility: hidden;
}
.curtain-text:hover {
  opacity: 1;
}
.result-container {
  padding: 5px;
  display: inline-block;
  /*  width: 150px; */
  border: 5px black;
  background: white;
  /*  height: 150px; */
  color: black;
  margin-bottom: 5px;
  margin: 10px;
  border-radius: 10px;
  text-align: left;
  opacity: 0.9;
  transition: all 0.2s ease-in-out;
  animation: fade-in 1s linear;
}

.curtain {
  position: absolute;
  height: 150px;
  width: 150px;
  margin-top: -5px;
  margin-left: -5px;
  border-radius: 10px;
  background-color: white;
  opacity: 0;
}

.curtain:hover {
  opacity: 0.5;
}
.searchresult-image {
  height: 140px;
  width: 140px;
}

.search-results-container {
  z-index: 2;
  height: 0px;
  position: absolute;
  width: 80vw;
  left: 12.5vw;
}

.result-text {
  overflow: hidden;
  font-size: 14px;
  font-family: "Roboto", arial, sans-serif;
  cursor: default;
}

.result-desc {
  padding-top: 25px;
  float: right;
  text-transform: uppercase;
  font-weight: 800;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-family: "Roboto", arial, sans-serif;
  color: #505050;
}

.result-desc a {
  text-decoration: none;
}

.result-header {
  height: 40px;
  background: #629780;
  margin: -10px;
  margin-bottom: -35px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.search-result-btn {
  position: absolute;
  height: 20px;
  border-radius: 4px;
  background-color: #6c7b95;
  color: white;
  visibility: hidden;
  outline: none;
}

.hover-btn {
  visibility: visible;
}
