.headerMenu {
  width: 100vw;
  background-color: white;
  color: darkslategrey;
  position: fixed;
  top: 61px;
  padding: 18px;
  box-shadow: -3px 12px 20px 0px #0000002e;
  box-sizing: border-box;
}

.headerMenu.desktop {
  height: 100vh;
  width: 260px;
  top: 0;
  position: fixed;
  padding: 0;
  padding-top: 100px;
  z-index: 1;
}
.headerMenu.desktop .item {
  font-size: 14px;
  padding-left: 18px;
}

.item {
  height: 40px;
  line-height: 40px;
  color: var(--icon-color-default) !important;
  font-weight: 600;
}

.icon {
  color: var(--icon-color-default);
  margin-right: 12px;
  opacity: 0.4;
  line-height: 40px !important;
}

.hideStyle {
  text-decoration: none;
  color: var(--icon-color-default) !important;
}

.input {
  padding: 3px 8px;
  background-color: rgb(201 200 200 / 40%);
  border: none;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.inputGroup {
  border: 1px solid lightgrey;
  padding: 0;
  line-height: 24px;
  height: 24px;
  display: flex;
  border: 1px solid lightgray;
  border-radius: 4px;
  margin: auto 0;
}
.copyBtn {
  margin-left: 8px;
  line-height: 24px !important;
}
.copyBtnCtr {
  background-color: white;
  border: none;
}

.copyEmailDesktop {
  margin-top: 8px;
  padding-right: 33px;
}
.showCopyBtn:before {
  padding: 8px;
}
.showCopyBtn:hover:before {
  background-color: #c3c3c3;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
}
