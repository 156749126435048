@media screen and (max-width: 650px) {
  /* styles for browsers larger than 960px; */
  .suggestionsList {
    width: 500px;
  }
}

.suggestionsList {
  z-index: 3;
  position: absolute;
  height: content;
  background: white;
  border-radius: 12px;
  color: black;
  align-self: top;
  display: inline-block;
}

.suggestion-input {
  width: 100%;
  height: 5vh;
  font-size: calc(12px + 1.5vmin);
}

.suggestionItem {
  margin: 0px;
 /* border-bottom: solid 1px #dcdada; */
  background: #1e232b;
  color: white;
  text-align: left;
  height: 16px;
  font-size: calc(4px + 2vmin);
  padding-left: 2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  font-family: arial, sans-serif;
}

.suggestionItem:hover{
  background-color: #244841;
  opacity: 0.8;
  color: black;
  cursor: pointer;
}

.hover{
  background-color: #244841;
  opacity: 0.8;
  color: black;
  cursor: pointer;
}

.history {
  color: #7b808a;
}
.history:hover{
  background-color: #1e232b;
  opacity: 1;
  color: #7b808a;
  cursor: not-allowed;
}

.suggestionItem a {
  color: inherit;
  text-decoration: none;
}
