.teamsContainer {
  min-height: 100px;
  max-width: 600px;
  width: 90vw;
  background-color: white;
  margin: 18px auto;
  border-radius: 4px;
  padding-bottom: 12px;
  text-align: left;
  padding-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 650px) {
  .teamsContainer {
    padding: 12px 12px 18px 12px;
  }
}
.team {
  color: var(--color-text-link);
  text-align: left;
  height: 30px;
  line-height: 40px;
  font-size: 12px;
  padding-left: 8px;
  font-weight: 600;
}
.icon {
  color: var(--icon-color-default);
}
.findTeam {
  border: transparent;
  width: 80%;
  margin-left: 8px;
  height: 28px;
  box-sizing: border-box;
}
.findTeam:focus {
  border: none;
  outline: none;
}

.searchIcon {
  color: #6d6d6d4d;
  margin: auto 8px;
  font-size: 14px;
}

.inputCtr {
  display: flex;
  border: 1px solid #6d6d6d4d;
  width: 95%;
  margin: auto;
  border-radius: 8px;
}
.teamName {
  text-decoration: none;
}
