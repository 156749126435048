.App {
  text-align: center;
}

@media screen and (max-width: 650px) {
  /* styles for browsers larger than 960px; */
  .face-avatar {
    margin-top: 40vmax !important; /* for mobile screens*/
  }
}

@media screen and (min-width: 650px) {
  .half {
    opacity: 0.5 !important;
  }
  .background {
    padding-left: 200px;
  }
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 30%;
  }
}

@keyframes slight-turn {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(20deg);
  }
}

@keyframes slight-turn-back {
  0% {
    transform: rotateY(20deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: orange;
  }
  50% {
    border-color: transparent;
  }
}

@keyframes fade-out {
  from {
    border-color: orange;
  }
  to {
    border-color: transparent;
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes float-in {
  from {
    left: -1000px;
  }
  to {
    left: 0px;
  }
}

@keyframes moon-up {
  from {
    margin-top: 100vh;
  }
  to {
    margin-top: 80vh;
  }
}
.welcome-text {
  animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end 3s,
    fade-out 5s;
  overflow: hidden;
  white-space: nowrap;
  border-right: 0.15em solid transparent; /* The typwriter cursor */
}

.App-logo {
  height: 25vmin;
  animation-name: rotation;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  position: absolute;
}

.shadow {
  /* margin-top: -8vmin; */
  animation-timing-function: linear;
  opacity: 0.3;
  animation-duration: 20s;
  position: relative;
}

.half {
  height: 5vh;
  margin-top: -8vmin;
  animation-duration: 2s;
  position: absolute;
  animation-timing-function: linear;
  opacity: 0;
}

.background {
  background-color: #282c34 !important;
  height: 100%;
  min-height: 100vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 60px;
  padding-bottom: 60px;
  box-sizing: border-box;
}

.App-link {
  color: #09d3ac;
}

.logo-container {
  padding-top: 10vh;
  position: absolute;
}

.face-avatar {
  height: 25vmin;
  margin-top: 40vmin;
  animation: slight-turn-back 1s normal;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.face-avatar:hover {
  animation: slight-turn 1s normal;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.loading-screen {
  font-size: 32px;
  animation: float-in 3s linear;
}

.welcome-text {
  position: absolute;
  margin-top: 50vh;
  width: 100vw;
  animation: float-in 3s;
}

.moon-image {
  width: 100vw;
  margin-top: 100vh;
  animation: moon-up 2s;
  animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  animation-fill-mode: forwards;
}
