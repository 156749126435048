.header {
  height: 60px;
  width: 100vw;
  background-color: white;
  position: fixed;
  padding: 18px 12px;
  box-sizing: border-box;
  box-shadow: -1px 1px 17px 0px #00000026;
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  z-index: 2;
}

.profileIcon {
  height: 40px;
  width: 40px;
  background-color: red;
  border-radius: 100%;
  margin-top: -6px;
  margin-right: 8px;
  background-position: center;
  background-size: contain;
}
.iconText {
  display: flex;
}

.typewriter {
  color: black;
  font-family: monospace;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  margin-left: 20px;
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
