.p-2 {
  padding: 2px;
}
.p-4 {
  padding: 4px;
}
.mr4 {
  margin-right: 4px;
}
.mr2 {
  margin-right: 2px;
}

.flex {
  display: flex;
  justify-content: space-between;
}
